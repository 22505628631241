import React from 'react'

const MonitorTaskContainer = Loader.loadBusinessComponent('MonitorTasks', 'MonitorTaskContainer')

const OutSiderMonitorTask = () => (
  <MonitorTaskContainer 
    libType={2}
    taskType='101502'
    currentMenu='outsiderTaskView'
    moduleName='outsiderTaskManage'
  />
)

export default OutSiderMonitorTask;